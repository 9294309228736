.ant-row {
  /*margin-bottom: 10px !important;*/
}

.ant-btn {
  border-radius: 5px !important;
}

.ant-btn-primary {
  background-color: #8f64ff !important;
  border-color: #8f64ff !important;
}

.ant-btn-primary[disabled] {
  background-color: #f5f5f5 !important;
  border-color: #d9d9d9 !important;
}

.ant-picker-now-btn {
  color: #999999;
}

.ant-picker-now-btn:hover {
  color: #262626;
}

/*.ant-tooltip-arrow {*/
/*  display: none !important;*/
/*}*/

.ant-form .ant-form-item {
  margin-bottom: 0 !important;
}

.ant-popover .ant-menu-inline,
.ant-popover .ant-menu-vertical,
.ant-popover .ant-menu-vertical-left {
  border-right: none;
}

.ant-modal-content {
  border-radius: 7px !important;
}

.ant-modal-header {
  border-top-left-radius: 7px !important;
  border-top-right-radius: 7px !important;
}

.ant-modal-title {
  font-weight: 600 !important;
}

.ant-avatar {
  font-weight: 600;
  border: 2px solid white !important;
}

.ant-avatar-string {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-drawer-close {
  height: 100%;
}

.ant-tabs-tab {
  font-size: 13px !important;
  font-weight: 600 !important;
}

.ant-tabs-tab+.ant-tabs-tab {
  margin: 0 0 0 15px !important;
}

.ant-scroll-number-only-unit {
  font-size: 11px !important;
  font-weight: 600 !important;
}

.ant-tooltip-inner {
  font-size: 13px !important;
  border-radius: 6px !important;
  padding: 4px 8px !important;
  min-height: unset !important;
}

.ant-tooltip-arrow {
  width: 18px !important;
  height: 18px !important;
}

.ant-tooltip-content {
  border-radius: 6px !important;
}

ul.ant-dropdown-menu,
.ant-select-dropdown {
  border: 1px solid #e4e4e4 !important;
  border-radius: 5px !important;
  padding: 6px 0 !important;
}

.ant-dropdown-menu-item {
  padding: 6px 12px !important;
}

.ant-dropdown-menu-item-selected {
  color: #40a9ff !important;
  font-weight: 500 !important;
}

.ant-select-item-option-selected {
  background-color: #f2f2f2 !important;
}

.ant-cascader-menu-item-active {
  background-color: #f2f2f2 !important;
}

.ant-picker,
.ant-input-number,
.ant-input-number-affix-wrapper {
  border-radius: 5px !important;
}

.anticon-calendar {
  display: none !important;
}

.ant-picker-panel-container {
  border: 1px solid #e4e4e4 !important;
  border-radius: 5px !important;
}

.ant-upload-drag {
  padding: 50px !important;
}

.ant-collapse-content-box {
  padding-top: 0 !important;
}

.ant-select-item.ant-select-item-option-disabled {
  background-color: #dadada;
}

.ant-form-item-explain {
  display: none;
}

.ant-image-mask-info {
  display: none;
}

.ant-input {
  border-radius: 5px !important;
  resize: none;
}

.ant-input[disabled] {
  color: #afabac !important;
  cursor: auto !important;
}

.ant-image-mask {
  border-radius: 5px;
}

.ant-input-number-handler-wrap {
  display: none;
}

.ant-select-selector {
  border-radius: 5px !important;
}

.ant-select-item.ant-select-item-option-disabled {
  background-color: transparent !important;
  cursor: auto !important;
}

/* (!) Effects all dropdown/select elements (!) */
.ant-select-selection-item {
  /*border-radius: 12px !important;*/
}

.ant-drawer-content-wrapper {
  box-shadow: none !important;
  border-left: 1px solid #f0f0f0 !important;
}

.ant-progress-text {
  font-size: 13px !important;
}

.ant-picker-time-panel-column>li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background-color: #f2f2f2 !important;
}

.ant-cascader-menu::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.ant-cascader-menu::-webkit-scrollbar-track {
  background: hsl(0, 0%, 95%);
  margin-block: -2px;
}

.ant-cascader-menu::-webkit-scrollbar-thumb {
  background: hsl(0, 0%, 85%);
  border-radius: 4px;
}

.ant-cascader-menu::-webkit-scrollbar-thumb:hover {
  background: hsl(0, 0%, 75%);
}

.ant-picker-content th {
  color: #999999 !important;
  font-size: 12px !important;
}

.ant-picker-cell-inner {
  font-size: 13px !important;
}

.ant-picker-cell-inner::before {
  border-width: 2px !important;
}

.ant-picker-today-btn {
  font-size: 13px !important;
  font-weight: 600 !important;
}

.ant-dropdown-menu-submenu-title {
  display: flex;
  align-items: center;
}

.ant-dropdown-menu-submenu-expand-icon {
  display: flex;
  align-items: center;
}

@supports (scrollbar-color: hsl(0, 0%, 85%) hsl(0, 0%, 97%)) {

  .ant-cascader-menu,
  .apexcharts-legend {
    scrollbar-color: hsl(0, 0%, 85%) hsl(0, 0%, 97%);
    scrollbar-width: thin;
  }

  .ant-cascader-menu {
    margin-block: -6px !important;
  }
}

.ant-message-notice-content {
  border-radius: 5px !important;
}