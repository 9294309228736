/* Main container */
.codex-editor__redactor {
  padding-bottom: 0 !important;
}

/* Plus button */
.ce-toolbar__plus {
  color: #999;
}

/* Paragraph placeholder */
.codex-editor--empty .ce-block:first-child .ce-paragraph[data-placeholder]:empty::before {
  color: rgb(191, 191, 191);
}

/* Dropdown menu icons and label */
.ce-popover__item-icon,
.ce-popover__item-label {
  color: rgba(0, 0, 0, 0.85);
}