* {
  font-family: 'Open Sans', sans-serif !important;
}

body {
  font-family: 'Open Sans', sans-serif !important;
  font-size: 14px;
  line-height: 20px;
}

/* Algolia re-styling */
.aa-Panel {
  z-index: 14;
  min-width: 238px;
}

.aa-DetachedSearchButton,
.aa-Form {
  height: 32px;
  border: 1px solid #e5e5e5;
  border-radius: 16px;
  transition: all ease 300ms;
}

.aa-Form:hover {
  border-color: #40a9ff;
}

.aa-Form:focus-within {
  border-color: #40a9ff;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  outline: none;
}

.aa-InputWrapperPrefix {
  height: 32px;
  width: 38px;
}

.aa-InputWrapper {
  height: 32px;
}

.aa-InputWrapperSuffix {
  height: 32px;
}

.aa-Input {
  height: 32px;
  font-size: 15px;
}

.aa-Input::placeholder {
  color: #bfbfbf;
}

.aa-SubmitButton {
  display: flex;
  align-items: center;
}

.aa-SubmitIcon {
  fill: #b0b0b0;
}

.aa-ClearIcon {
  fill: #b0b0b0;
}

.aa-PanelLayout,
.aa-Panel--scrollable {
  padding: 0;
}

.aa-List {
  padding: 6px 0;
}

.aa-Item {
  padding: 8px 16px;
  border-radius: 0;
  transition: background-color ease 300ms;
}

.aa-Item:hover {
  background-color: #f6f6f6;
}

mark {
  padding: 0 !important;
  background-color: rgba(24, 144, 255, 0.1) !important;
}